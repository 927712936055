<template>
  <div class="container">
    <div class="dialogue-box has-header d-flex justify-content-center">
      <div class="dialogue-box-header">Your History ({{ count }})</div>
      <div class="dialogue-box-inner p-4 px-5 w-100">
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th></th>
              <th>Item Name</th>
              <th>Amount</th>
              <th>Price Per Item</th>
              <th>Total Price</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="listing in history" :key="listing.id" class="align-middle">
              <td>
                <Item :item="listing.MarketListing" :listing="true" :hide-quantity="true"/>
              </td>
              <td>
                {{ listing.MarketListing.Item.name }} <span v-if="canRefine(listing)">+{{ refineLevel(listing) }}</span></td>
              <td>x{{ listing.amount }}</td>
              <td>{{ formatPrice(listing.MarketListing.price) }}</td>
              <td>{{ formatPrice(listing.MarketListing.price * listing.amount) }}</td>
              <th>
                <span class="text-danger" v-if="listing.status === 'Sold'">Sold</span>
                <span class="text-success" v-else-if="listing.status === 'Bought'">Bought</span>

                <div v-tooltip="listing.createdAt">{{ (new Date(listing.createdAt)).toLocaleDateString() }}</div>
              </th>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td colspan="6" class="">
                <div class="d-flex align-items-center justify-content-around w-100">
                  <button class="btn btn-primary" @click="previous">Previous</button>
                  <span>
                    {{ page }} / {{ Math.ceil(count / 15) }}
                  </span>
                  <button class="btn btn-primary" @click="next">Next</button>
                </div>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="dialogue-bg"></div>
    </div>
  </div>
</template>
<script>
import Item from "@/components/items/Item";

export default {
  name: 'MarketplaceHistory',
  components: {Item},
  data() {
    return {
      history: [],
      page: 1,
      count: 0,
      maxPage: 1,
    }
  },
  mounted() {
    this.$socket.emit('marketplace:get own history', {
      page: this.page,
    })
  },
  computed: {},
  sockets: {
    MARKET_HISTORY({history, count}) {
      if (history.length > 0)
        this.history = history;
      else
        this.maxPage = this.page;

      this.count = count;
    }
  },
  methods: {
    refineLevel(listing) {
      let attr = listing.MarketListing.item_props;

      if(typeof attr === 'string')
        attr = JSON.parse(attr).ItemAttributes;
      else
        attr = attr.ItemAttributes;

      if(attr && attr.length > 0) {
        return attr.find(s => s.attribute === 'refineLevel')?.value
      }

      return false;
    },
    formatPrice(number) {
      return new Intl.NumberFormat('en-gb').format(number);
    },
    previous() {
      if (this.page <= 1) return;
      this.page--;
      this.$socket.emit("marketplace:get own history", {
        page: this.page,
      })
    },
    next() {
      if (this.page >= Math.ceil(this.count / 15)) return;
      this.page++;
      this.$socket.emit("marketplace:get own history", {
        page: this.page,
      })
    },
    canRefine(listing) {
      return !!this.refineLevel(listing)
    }
  }
}
</script>